<script>
import {
  ArrowUpIcon,
  ArrowRightIcon,
} from 'vue-feather-icons';

import Navbar from '@/components/navbar';
import Footer from '@/components/footer';

/**
 * Page-aboutus-two component
 */
export default {
  data() {
    return {};
  },
  components: {
    Navbar,
    Footer,
    ArrowUpIcon,
    ArrowRightIcon,
  },
};
</script>

<template>
<div>
    <Navbar :nav-light="true" />
    <section class="bg-half d-table w-100" style="background: url('images/about-top.jpeg');">
        <div class="bg-overlay"></div>
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-12 text-center">
                    <div class="page-next-level title-heading">
                        <h1 class="text-white title-dark"> 關於嗨嗨 </h1>
                        <p class="text-white-50 para-desc mb-0 mx-auto">嗨，我們是嗨嗨數位，我們創造世界上最好用最好看的系統</p>
                        <div class="page-next">
                            <nav aria-label="breadcrumb" class="d-inline-block">
                                <ul class="breadcrumb bg-white rounded shadow mb-0">
                                    <li class="breadcrumb-item">
                                        <router-link to="/">首頁</router-link>
                                    </li>
                                    <li class="breadcrumb-item active" aria-current="page">關於嗨嗨</li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div class="position-relative">
        <div class="shape overflow-hidden text-white">
            <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
            </svg>
        </div>
    </div>
    <section class="section">
        <div class="container">
            <div class="row align-items-center" id="counter">
                <div class="col-md-6">
                    <img src="/images/about-01.svg" class="img-fluid" alt="">
                </div>
                <div class="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                    <div class="ml-lg-4">
                        <div class="section-title">
                            <h4 class="title mb-4">關於嗨嗨數位</h4>
                            <p class="text-muted">
                              嗨嗨數位成立於 2019 年，我們協助客戶打造最好用且最好看的各式系統，並協助各領域的專業進行數位轉型，將原先複雜繁瑣的紙本流程轉變成簡單易用的雲端服務，我們的願景就是竭盡所能，透過我們的專業，讓您的專業更具價值更具競爭力，締造雙贏。
                            </p>
                            <a href="javascript:void(0)" class="btn btn-primary mt-3">你有想法？聯絡我們吧</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--end section-->

    <section class="section bg-light">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12">
                    <div class="section-title text-center mb-4 pb-2">
                        <h6 class="text-primary">Work Process</h6>
                        <h4 class="title mb-4">合作流程</h4>
                        <p class="text-muted para-desc mx-auto mb-0">一起讓 <span class="text-primary font-weight-bold">嗨嗨數位</span> 成為你最專業的數位夥伴</p>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->

            <div class="row">
                <div class="col-md-4 mt-4 pt-2">
                    <div class="card features work-process bg-transparent process-arrow border-0 text-center">
                        <div class="icons rounded h1 text-center text-primary px-3">
                            <i class="uil uil-clipboard-notes"></i>
                        </div>

                        <div class="card-body">
                            <h4 class="title text-dark">需求了解/研究/規劃</h4>
                            <p class="text-muted mb-0">透過與客戶訪談，先了解整個需求，並開始進行初步的研究和規劃</p>
                        </div>
                    </div>
                </div>
                <!--end col-->

                <div class="col-md-4 mt-md-5 pt-md-3 mt-4 pt-2">
                    <div class="card features work-process bg-transparent process-arrow border-0 text-center">
                        <div class="icons rounded h1 text-center text-primary px-3">
                            <i class="uil uil-desktop-alt"></i>
                        </div>

                        <div class="card-body">
                            <h4 class="title text-dark">開發/測試</h4>
                            <p class="text-muted mb-0">依照定義好的里程碑進行開發，並在每個階段提供測試和驗收，確保開發方向和內容與預期一致</p>
                        </div>
                    </div>
                </div>
                <!--end col-->

                <div class="col-md-4 mt-md-5 pt-md-5 mt-4 pt-2">
                    <div class="card features work-process bg-transparent d-none-arrow border-0 text-center">
                        <div class="icons rounded h1 text-center text-primary px-3">
                            <i class="uil uil-server"></i>
                        </div>

                        <div class="card-body">
                            <h4 class="title text-dark">維運/監控</h4>
                            <p class="text-muted mb-0">我們提供高規格的雲伺服器維運服務，讓您可以無需顧慮系統或網站，全新在您的事業上</p>
                        </div>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>
    </section>
    <!--end section-->

    <!--end section-->
    <Footer />
    <!-- Footer End -->
    <!-- Back to top -->
    <a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" v-scroll-to="'#topnav'">
        <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
</div>
</template>

<style>
.modal-content {
    background-color: transparent;
}
</style>
